<template>
  <b-modal
    size="lg"
    centered
    lazy
    :title="title"
    v-model="show"
    header-bg-variant="info"
    :hide-footer="true"
    scrollable
  >
  <div class="scroll-image-group">
    <div v-for="(image, index) in images" :key="index" class="scroll-image-element">
      <div class="image-container">
        <img :src="image.url" width="100%">
      </div>
    </div>
  </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },

  props: {
    images: {
      type: Array
    },
    title: {
      type: String
    }
  },

  methods: {
    showModal() {
      this.show = true;
    }
  }
}
</script>

<style>
  .image-container {
    max-width: 400px;
    min-width: 400px;
  }

  .scroll-image-group {
    white-space: nowrap;
  }

  .scroll-image-element {
    display: inline-block;
  }

  @media (max-width: 991px) {
    .image-container {
      max-width: 250px;
      min-width: 250px;
    }
  }
</style>