<template>
  <div class="row">
    <div class="col-auto pr-0">
      <b-form-checkbox v-model="item.automaticItemLot" :disabled="disabled" @change="fireChanged(item, $event)"></b-form-checkbox>
    </div>
    <div class="col-auto pl-0 pr-2">
      <label>Lote automático</label>
    </div>
    <div class="col-auto pl-0">
      <button :id="item.id + '-popover'" class="btn btn-link p-0">
        <i class="fa fa-info-circle"></i>
      </button>
      <b-popover :target="item.id + '-popover'" triggers="hover" placement="top">
        <span v-if="item.automaticItemLot">Os lotes do item não ficarão visíveis ao movimentar pedidos. Na entrada os lotes serão criados automaticamente e na saída o sistema irá retirar do lote mais antigo</span>
        <span v-else>Esse item será controlado por <b>lote</b></span>
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'automatic-item-lot-checkbox',

  props: {
    item: {
      type: Object
    },
    disabled: {
      type: Boolean
    },
    changed: {
      type: Function
    }
  },

  methods: {
    fireChanged(item, $event) {
      if (this.changed) {
        this.changed(item, $event);
      }
    }
  }
}
</script>
