<template>
  <b-modal
      size="lg"
      centered
      lazy
      :title="'Alterar todos os itens desse filtro'"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="show"
      :hide-footer="true"
      id="patch-all-modal"
    >
      <b-container>
        <div class="card">
          <div class="card-header">Endereço padrão</div>
          <div class="card-body">
            <div class="row">

              <div class="col">
                <div class="row">
                  <div class="col-auto pr-0">
                    <b-form-checkbox v-model="dontChangeDefaultLocation" :disabled="loading"></b-form-checkbox>
                  </div>
                  <div class="col pl-0">
                    <label>Não alterar</label>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="row align-items-end">

                  <div class="col-sm-10 col-10">
                    <label>Endereço padrão:</label>
                    <model-list-select
                      :list="locations"
                      option-value="id"
                      option-text="name"
                      v-model="item.defaultLocation"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchLocation(-1, $event)"
                      :isDisabled="loading || dontChangeDefaultLocation"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>

                  <div class="col-sm-2 col-2 pl-0">
                    <div v-if="searchLocationInProcess">
                      <animation />
                    </div>
                    <b-button
                      v-else
                      variant="secondary"
                      @click.prevent="openLocationBarcodeInput"
                      :disabled="loading || dontChangeDefaultLocation"
                    >
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">Lote automático</div>
          <div class="card-body">
            <div class="row">

              <div class="col">
                <div class="row">
                  <div class="col-auto pr-0">
                    <b-form-checkbox v-model="dontChangeAutomaticItemLot" :disabled="loading"></b-form-checkbox>
                  </div>
                  <div class="col pl-0">
                    <label>Não alterar</label>
                  </div>
                </div>
              </div>

              <div class="col">
                <automatic-item-lot-checkbox
                  :item="item"
                  :disabled="loading || dontChangeAutomaticItemLot"
                ></automatic-item-lot-checkbox>
              </div>

            </div>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-auto">
            <animation v-if="loading" />
            <button v-else class="btn btn-warning" @click="openPatchConfirmationModal">Alterar</button>
          </div>
        </div>

        <barcode-input-modal
          id="inner-location-barcode-input-modal"
          :attempts="locationBarcodeAttempts"
          :loading="loadingLocationBarcode"
          :ok="readLocationBarcode"
        ></barcode-input-modal>

        <confirmation-modal
          id="patch-confirmation-modal"
          :msg="confirmationMessage"
          :ok="confirmPatch"
        ></confirmation-modal>
      </b-container>
    </b-modal>
</template>

<script>
import { httpClient } from '@/service'
import Animation from '@/components/loaders/animation'
import { LoadingAnimation } from '@/components/loaders'
import AutomaticItemLotCheckbox from '@/components/automatic-item-lot-checkbox'
import { ModelListSelect } from 'vue-search-select'
import BarcodeInputModal from "@/components/common/barcode-input-modal";
import ConfirmationModal from '@/components/common/confirmation-modal';

export default {
  name: 'patch-all-modal',
  components: { Animation, LoadingAnimation, AutomaticItemLotCheckbox, ModelListSelect, BarcodeInputModal, ConfirmationModal },

  data() {
    return {
      show: false,
      dontChangeAutomaticItemLot: true,
      dontChangeDefaultLocation: true,
      loading: false,
      item: {
        defaultLocation: {},
        automaticItemLot: null
      },
      loadingLocationBarcode: false,
      locationBarcodeAttempts: 0,
      confirmationMessage: null
    }
  },

  props: {
    locations: {
      type: Array
    },
    searchLocationInProcess: {
      type: Boolean
    },
    searchLocation: {
      type: Function
    },
    unshiftLocation: {
      type: Function
    },
    getCondition: {
      type: Function,
      require
    },
    prepareItem: {
      type: Function
    },
    reloadItems: {
      type: Function
    }
  },

  mounted() {
  },

  methods: {
    showModal() {
      this.show = true;
    },

    alwaysTrueFilterPredicate() {
      return true;
    },

    openLocationBarcodeInput() {
      this.$bvModal.show("inner-location-barcode-input-modal");
    },

    readLocationBarcode(barcode) {
      if (!this.loadingLocationBarcode) {
        if (barcode && barcode.length) {
          this.loadingLocationBarcode = true;
          httpClient
            .get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then((data) => {
              this.loadingLocationBarcode = false;

              if (data.data.data) {
                this.unshiftLocation(data.data.data);
                this.item.defaultLocation = data.data.data;
                this.$bvModal.hide("inner-location-barcode-input-modal");
                this.$notify.success("Endereço encontrado");
              } else {
                this.$notify.textError("Endereço não encontrado");
              }

              this.locationBarcodeAttempts++;
            })
            .catch((error) => {
              this.loadingLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                );
              }

              this.locationBarcodeAttempts++;
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }

      this.locationBarcodeAttempts++;
    },

    openPatchConfirmationModal() {
      if (this.dontChangeAutomaticItemLot && this.dontChangeDefaultLocation) {
        this.$notify.textError(
          "Informe ao menos uma alteração"
        );
        return;
      }

      this.setConfirmationMessage();
      this.$bvModal.show('patch-confirmation-modal');
    },

    setConfirmationMessage() {
      let message = 'Tem certeza de que deseja alterar todos os desse filtro?';
      message += '\nAlterações à serem aplicadas:';

      if (!this.dontChangeAutomaticItemLot) {
        message += '\nLote automático: ' + (this.item.automaticItemLot ? 'Sim' : 'Não');
      }

      if (!this.dontChangeDefaultLocation) {
        message += '\nEndereço padrão: ' + (this.item.defaultLocation ? this.item.defaultLocation.name : 'Nenhum');
      }

      this.confirmationMessage = message;
    },

    confirmPatch() {
      let condition = this.getCondition();
      console.log(condition);

      if (this.prepareItem) {
        this.item = this.prepareItem(this.item);
      }

      let params = {};

      if (!this.dontChangeAutomaticItemLot) {
        if (!this.item.automaticItemLot) {
          this.item.automaticItemLot = false;
        }

        params.automaticItemLot = this.item.automaticItemLot;
      }

      if (!this.dontChangeDefaultLocation) {
        params.defaultLocationId = this.item.defaultLocationId;
      }

      console.log(params);

      this.loading = true;
      httpClient
      .patch(`${process.env.VUE_APP_API_URL}items/multiple-by-condition`, condition, { params })
      .then(({ data }) => {
        this.loading = false;
        this.$notify.success('Alterações executadas com sucesso');

        if (this.reloadItems) {
          this.reloadItems();
          this.show = false;
        }
      })
      .catch((error) => {
        this.loading = false
        if (error.message) {
          this.$notify.error(error)
        }
      });
    }
  }
}
</script>
