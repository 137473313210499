<template>
  <div>
    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-header cursor-pointer" @click="showFilters = !showFilters">
            Filtros
            <i
              :class="
                'fa fa-lg ' +
                (showFilters ? 'fa-caret-down' : 'fa-caret-up') +
                ' float-right mt-1 mr-0'
              "
            ></i>
          </div>
          <div v-if="showFilters" class="card-body">
            <b-row class="mx-md-n3 mx-1">
              <div class="col-xl-8 col-lg-7 col-md-7 col-12">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    @keyup.enter="onFiltering"
                    placeholder="Procurar por..."
                  />
                  <b-input-group-append>
                    <b-button size="md" text="Button" variant="outline-info" @click.passive="onFiltering" class="cursor-pointer">
                      <i class="cui-magnifying-glass"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-12 mt-md-0 mt-3">
                <model-list-select
                  :list="automaticLotsOptions"
                  option-value="value"
                  option-text="label"
                  v-model="selectedAutomaticLotOption"
                  placeholder="Lote automático ou não"
                />
              </div>

              <div class="col-12 mt-3">
                <div class="row">

                  <div class="col-12 col-md-6 mb-3">
                    <div class="row align-items-center">
                      <div class="col-md-auto col-12 mb-md-0 mb-1 pr-0">
                        <label class="mb-0">Família:</label>
                      </div>
                      <div class="col pl-md-1 pl-3">
                        <model-list-select
                          :list="families"
                          option-value="id"
                          option-text="name"
                          v-model="selectedFamily"
                          placeholder="Digite para pesquisar..."
                          @searchchange="searchFamily"
                          @keyup.enter="onFiltering"
                          :filterPredicate="alwaysTrueFilterPredicate"
                        />
                      </div>
                      <div v-if="loadingFamilies" class="col-auto ml-3">
                        <loading-animation />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 pl-md-0 pl-3 mb-3">
                    <div class="row align-items-center">
                      <div class="col-md-auto col-12 mb-md-0 mb-1 pr-0">
                        <label class="mb-0">Grupo:</label>
                      </div>
                      <div class="col pl-md-1 pl-3">
                        <model-list-select
                          :list="groups"
                          option-value="id"
                          option-text="name"
                          v-model="selectedGroup"
                          placeholder="Digite para pesquisar..."
                          @searchchange="searchGroup"
                          @keyup.enter="onFiltering"
                          :filterPredicate="alwaysTrueFilterPredicate"
                        />
                      </div>
                      <div v-if="loadingGroups" class="col-auto ml-3">
                        <loading-animation />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 pl-md-0 pl-3 mb-3">
                    <div class="row align-items-center">
                      <div class="col-md-auto col-12 mb-md-0 mb-1 pr-0">
                        <label class="mb-0">Sub-Grupo:</label>
                      </div>
                      <div class="col pl-md-1 pl-3">
                        <model-list-select
                          :list="subGroups"
                          option-value="id"
                          option-text="name"
                          v-model="selectedSubGroup"
                          placeholder="Digite para pesquisar..."
                          @searchchange="searchSubGroup"
                          @keyup.enter="onFiltering"
                          :filterPredicate="alwaysTrueFilterPredicate"
                        />
                      </div>
                      <div v-if="loadingSubGroups" class="col-auto ml-3">
                        <loading-animation />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 pl-md-0 pl-3">
                    <div class="row align-items-center">
                      <div class="col-md-auto col-12 mb-md-0 mb-1 pr-0">
                        <label class="mb-0">Marca:</label>
                      </div>
                      <div class="col pl-md-1 pl-3">
                        <model-list-select
                          :list="brands"
                          option-value="id"
                          option-text="name"
                          v-model="selectedBrand"
                          placeholder="Digite para pesquisar..."
                          @searchchange="searchBrand"
                          @keyup.enter="onFiltering"
                          :filterPredicate="alwaysTrueFilterPredicate"
                        />
                      </div>
                      <div v-if="loadingBrands" class="col-auto ml-3">
                        <loading-animation />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-auto mt-3 mt-md-0">
                    <div class="row">
                      <div class="col-auto pr-0">
                        <b-form-checkbox v-model="itemPositives" @change="filterItemPositves($event)"></b-form-checkbox>
                      </div>
                      <div class="col-auto pl-0 pr-2">
                        <label>Apenas com saldo positivo</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md">
                    <b-form-input
                      v-model="minLocationCount"
                      type="number"
                      @keyup.enter="onFiltering"
                      placeholder="Número mínimo de endereços"
                    />
                  </div>

                </div>
              </div>

              <div v-if="isUserManager" class="col mt-3">
                <button class="btn btn-warning float-right mobile-btn-100" @click="$refs.patchAllModal.showModal()"
                  :disabled="loadingRows || saving">
                  Alterar todos os itens desse filtro
                </button>
              </div>

            </b-row>
          </div>
        </div>
      </div>
    </b-row>

    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div v-if="!loadingRows && !getItems().length" class="mb-3">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Nenhum Item encontrado
                </div>
              </div>
            </div>

            <div v-if="getItems().length" class="mb-3">
              <div v-for="(item, index) in getItems()" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col-2 img-fix-size-suggestions px-0 px-sm-2 cursor-pointer" v-if="item.images && item.images.length"
                  @click.prevent="openImagemModal(item.images)">
                  <img :src="item.images[0].url" width="100%">
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="row">
                    <div class="col-12">
                      <span class="font-weight-bold">Descrição: </span>{{item.description}}
                    </div>
                    <div class="col-12">
                      <span class="font-weight-bold">Reduzido: </span>{{getItemReduzidoFromRef(item.ref)}}
                    </div>
                    <div class="col-12" v-if="item.sku">
                      <span class="font-weight-bold">Referência: </span>{{item.sku}}
                    </div>
                    <div class="col-12" v-if="item.gtin">
                      <span class="font-weight-bold">Cód. barras: </span>{{item.gtin}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm col-12">
                  <span class="font-weight-bold">Alocados: </span>{{item.totalAllocated}}
                </div>

                <div class="col-12">
                  <div class="row align-items-end">

                    <div class="col-md-5 col-12 mb-md-0 mb-3">
                      <div class="row align-items-end">

                        <div class="col-sm-10 col-10">
                          <label>Endereço padrão:</label>
                          <model-list-select
                            :list="locations"
                            option-value="id"
                            option-text="name"
                            v-model="item.defaultLocation"
                            placeholder="Digite para pesquisar..."
                            @searchchange="searchLocation(index, $event)"
                            :isDisabled="saving"
                            :filterPredicate="alwaysTrueFilterPredicate"
                            @input="saveItem(item, $event)"
                          />
                        </div>

                        <div class="col-sm-2 col-2 pl-0">
                          <div v-if="searchLocationInProcess">
                            <animation />
                          </div>
                          <b-button
                            v-else
                            variant="secondary"
                            @click.prevent="openLocationBarcodeInput(index)"
                            :disabled="saving"
                          >
                            <i class="fa fa-barcode"></i>
                          </b-button>
                        </div>

                      </div>
                    </div>

                    <div class="col-md-auto col-sm-6 col-12">
                      <automatic-item-lot-checkbox
                        :item="item"
                        :disabled="saving"
                        :changed="saveItem"
                      ></automatic-item-lot-checkbox>
                    </div>

                    <div class="col-md-auto col-sm-6 col-12 mt-sm-0 mt-3">
                      <b-button variant="info" class="rounded-0"
                        @click.passive="showItemDetails(item)"
                        :disabled="saving">
                        <i class="cui-magnifying-glass"></i> Visualizar Detalhes
                      </b-button>
                      <a :href="window.location.origin + '/warehouse/items/details/' + item.id"
                        target="_blank" class="btn btn-secondary rounded-0 mr-1 px-2"
                        title="Abrir em uma nova guia">
                        <i class="fa fa-window-restore"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div v-if="loadingRows" class="m-3">
              <vcl-facebook v-for="index in perPage" :key="index" :height="160" style="margin-right: 100%;"></vcl-facebook>
            </div>

            <!-- <nav>
              <b-pagination
                v-if="totalRows"
                align="center"
                :limit="10"
                :total-rows="totalRows"
                :per-page="perPage"
                prev-text="Anterior"
                next-text="Próximo"
                v-model="currentPage"
                @change="pageChanged"
                :disabled="loadingRows"/>
            </nav> -->
          </div>
        </div>
      </div>
    </b-row>

    <div id="loading-animation">
      <div v-if="saving">
        <loading-animation />
      </div>
    </div>

    <barcode-input-modal
      id="location-barcode-input-modal"
      :attempts="locationBarcodeAttempts"
      :loading="loadingLocationBarcode"
      :ok="readLocationBarcode"
    ></barcode-input-modal>

    <image-modal
      ref="imageModal"
      title="Imagens do item"
      :images="itemImages"
    ></image-modal>

    <patch-all-modal
      ref="patchAllModal"
      :locations="locations"
      :searchLocationInProcess="searchLocationInProcess"
      :searchLocation="searchLocation"
      :unshiftLocation="(location) => locations.unshift(location)"
      :getCondition="getCondition"
      :prepareItem="prepareItem"
      :reloadItems="onFiltering"
    ></patch-all-modal>
  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import Animation from '@/components/loaders/animation'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'
  import { ModelListSelect } from 'vue-search-select'
  import BarcodeInputModal from "@/components/common/barcode-input-modal";
  import ImageModal from '@/components/common/image-modal';
  import PatchAllModal from '@/views/warehouse/item/patch-all-modal';
  import AutomaticItemLotCheckbox from '@/components/automatic-item-lot-checkbox'
  import { VclFacebook } from 'vue-content-loading';
  import { auth } from "@/service";

  const PAGE_SIZE = 10;

  export default {
    name: 'items-table',
    components: {
      Animation,
      LoadingAnimation,
      ModelListSelect,
      BarcodeInputModal,
      ImageModal,
      PatchAllModal,
      AutomaticItemLotCheckbox,
      VclFacebook
    },

    props: {
      id: {
        type: String,
        require
      },
      fields: {
        type: [Array, Object],
        default: () => [],
      },
      url: {
        type: String,
      },
      resourceName: {
        type: String,
      },
    },

    data: () => {
      return {
        filter: null,
        currentPage: 1,
        perPage: PAGE_SIZE,
        items: [],
        loadingRows: false,
        hasNextRow: true,
        selectedAutomaticLotOption: {},
        automaticLotsOptions: [
          { label: 'Todos', value: null },
          { label: 'Apenas com lote automático', value: 'A' },
          { label: 'Apenas com lote manual', value: 'M' }
        ],
        families: [{id: null, name: 'Todas'}],
        loadingFamilies: false,
        selectedFamily: {},
        groups: [{id: null, name: 'Todos'}],
        loadingGroups: false,
        selectedGroup: {},
        subGroups: [{id: null, name: 'Todos'}],
        loadingSubGroups: false,
        selectedSubGroup: {},
        brands: [{id: null, name: 'Todas'}],
        loadingBrands: false,
        selectedBrand: {},
        showFilters: false,
        saving: false,
        searchLocationInProcess: false,
        locations: [],
        locationSearchTimeout: null,
        loadingLocationBarcode: false,
        locationBarcodeAttempts: 0,
        selectedItemIndex: -1,
        itemImages: [],
        isUserManager: false,
        authUser: false,
        ownRoute: null,
        window: window,
        itemPositives: false,
        minLocationCount: null
      }
    },

    mounted() {
      this.ownRoute = this.$route.fullPath;
      this.setIsUserManager();
      this.filter = shared.getLocalStorageString('item/itemsFilter')
      this.itemPositives = shared.getLocalStorageString('item/positive', this.itemPositives);
      this.minLocationCount = shared.getLocalStorageString('item/minLocationCount')
      this.selectedAutomaticLotOption = shared.getLocalStorageObj('item/selectedAutomaticLotOption');

      let oldPage = Number(shared.getLocalStorageString('item/currentPage'));

      if (!oldPage) {
        oldPage = 1;
      }

      if (oldPage <= 10) {
        this.perPage = oldPage * this.perPage;
      } else {
        this.currentPage = oldPage;
      }

      this.selectedFamily = shared.getLocalStorageObj('item/selectedFamily');
      if (this.selectedFamily && this.selectedFamily.id && Object.keys(this.selectedFamily).length) {
        this.families.push(this.selectedFamily);
      }

      this.selectedGroup = shared.getLocalStorageObj('item/selectedGroup');
      if (this.selectedGroup && this.selectedGroup.id && Object.keys(this.selectedGroup).length) {
        this.groups.push(this.selectedGroup);
      }

      this.selectedSubGroup = shared.getLocalStorageObj('item/selectedSubGroup');
      if (this.selectedSubGroup && this.selectedSubGroup.id && Object.keys(this.selectedSubGroup).length) {
        this.subGroups.push(this.selectedSubGroup);
      }

      this.selectedBrand = shared.getLocalStorageObj('item/selectedBrand');
      if (this.selectedBrand && this.selectedBrand.id && Object.keys(this.selectedBrand).length) {
        this.brands.push(this.selectedBrand);
      }

      this.getPage({
        apiUrl: this.url,
        currentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: "",
        sortDesc: false,
        filter: ''
      });
      this.listenToScrollBottom();
    },

    methods: {
      getItems() {
        return this.items;
      },

      getPage(context) {
        if (this.loadingRows || this.saving) {
          return;
        }

        const sortBy = context.sortBy ? `&sort=${context.sortBy},${context.sortDesc ? 'desc' : 'asc'}` : ''
        let condition = this.getCondition();

        this.loadingRows = true;
        return httpClient.post(
          `${context.apiUrl}/select-and-where/with-quantity?page=${context.currentPage - 1}&size=${context.perPage}${sortBy}`,
          {
            where: condition,
            select: [
              'id',
              'defaultLocation.id',
              'defaultLocation.name',
              'automaticItemLot',
              'description',
              'ref',
              'sku',
              'gtin'
            ]
          }
        ).then(({ data }) => {
          return this.handleData(data);
        }).catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.items = [];
          return []
        });
      },

      getCondition() {
        let having = undefined;
        let conditions = [
          {
            field: 'active',
            value: true
          }
        ];

        if (this.filter && this.filter.length) {
          conditions.push({
            logicalOperator: 'OR',
            conditions: [
              {
                field: 'description',
                conditionalOperator: 'LIKE',
                value: this.filter
              },
              {
                field: 'ref',
                conditionalOperator: 'LIKE_END',
                value: shared.mountReduzidoFilter(this.filter)
              },
              {
                field: 'sku',
                conditionalOperator: 'LIKE',
                value: this.filter
              },
              {
                field: 'gtin',
                conditionalOperator: 'LIKE',
                value: this.filter
              }
            ]
          });
        }

        if (this.selectedAutomaticLotOption && this.selectedAutomaticLotOption.value) {
          if (this.selectedAutomaticLotOption.value === 'A') {
            conditions.push({
              field: 'automaticItemLot',
              value: true
            });
          } else if (this.selectedAutomaticLotOption.value === 'M') {
            conditions.push({
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'automaticItemLot',
                  value: false
                },
                {
                  field: 'automaticItemLot',
                  conditionalOperator: 'IS_NULL'
                }
              ]
            });
          }
        }

        if (this.selectedFamily && this.selectedFamily.id) {
          conditions.push({
            field: 'family.id',
            value: this.selectedFamily.id
          });
        }

        if (this.selectedGroup && this.selectedGroup.id) {
          conditions.push({
            field: 'group.id',
            value: this.selectedGroup.id
          });
        }

        if (this.selectedSubGroup && this.selectedSubGroup.id) {
          conditions.push({
            field: 'subGroup.id',
            value: this.selectedSubGroup.id
          });
        }

        if (this.selectedBrand && this.selectedBrand.id) {
          conditions.push({
            field: 'brand.id',
            value: this.selectedBrand.id
          });
        }

        if (this.itemPositives) {
          conditions.push({
            field: 'itemLots.balances.entries',
            conditionalOperator: 'GREATER_THAN',
            expressionValue: 'itemLots.balances.exits'
          })
        }

        if (this.minLocationCount) {
          this.minLocationCount = Number(this.minLocationCount);

          if (this.minLocationCount > 0) {
            having = {
              logicalOperator: 'OR',
              conditions: [
                {
                  "field": "countDistinct(itemLots.balances.location.id)",
                  "conditionalOperator": "GREATER_THAN_OR_EQUAL_TO",
                  "value": this.minLocationCount,
                  "joinType": "LEFT"
                },
                {
                  "field": "countDistinct(locations.id)",
                  "conditionalOperator": "GREATER_THAN_OR_EQUAL_TO",
                  "value": this.minLocationCount,
                  "joinType": "LEFT"
                }
              ]
            };
          }
        }

        return {
          conditions,
          having,
          groupBy: ["id"]
        };
      },

      pageChanged(page) {
        this.getPage({
          apiUrl: this.url,
          currentPage: page,
          perPage: this.perPage,
          sortBy: "",
          sortDesc: false
        })
      },

      filterItemPositves($event) {
        this.itemPositives = $event
        shared.setLocalStorageString('item/positive', this.itemPositives)
        this.onFiltering()
      },

      handleData(data) {
        this.loadingRows = false;
        this.hasNextRow = !data.data.last;

        data.data.content.forEach(item => {
          if (item.defaultLocation && item.defaultLocation.id && !this.locations.find(l => l.id === item.defaultLocation.id)) {
            this.locations.push(item.defaultLocation);
          }
        });

        if (!this.items) {
          this.items = [];
        }

        this.items.push(...data.data.content);

        this.currentPage = data.data.number + 1;

        if (this.perPage > PAGE_SIZE) {
          this.currentPage = this.perPage / PAGE_SIZE;

          this.$nextTick(() => {
            shared.restoreScrollPosition('item/scrollPosition');
          });
        }

        shared.setLocalStorageString('item/currentPage', this.currentPage);
        this.perPage = PAGE_SIZE;

        return this.items
      },

      async onFiltering() {
        this.items = [];
        this.currentPage = 1;
        this.hasNextRow = true;

        shared.setLocalStorageString('item/itemsFilter', this.filter);
        shared.setLocalStorageString('item/minLocationCount', this.minLocationCount);

        this.getPage({
          apiUrl: this.url,
          currentPage: this.currentPage,
          perPage: this.perPage,
          sortBy: "",
          sortDesc: false
        })
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      async searchFamily (searchText) {
        if (!this.loadingFamilies && (searchText && searchText.length)) {
          if (this.familySearchTimeout) {
            clearTimeout(this.familySearchTimeout);
          }

          this.familySearchTimeout = setTimeout(() => {
            this.loadingFamilies = true
            httpClient
            .post(`${process.env.VUE_APP_API_URL}families/condition?page=${0}&size=${10}`, {
              conditions: [
                {
                  field: 'name',
                  conditionalOperator: 'LIKE_START',
                  value: searchText
                },
              ]
            })
            .then(({ data }) => {
              this.loadingFamilies = false;
              this.families = data.data.content;
              this.families.unshift({id: null, name: 'Todas'});
            })
            .catch((error) => {
              this.loadingFamilies = false
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }, 500);
        }
      },

      async searchGroup (searchText) {
        if (!this.loadingGroups && (searchText && searchText.length)) {
          if (this.groupSearchTimeout) {
            clearTimeout(this.groupSearchTimeout);
          }

          this.groupSearchTimeout = setTimeout(() => {
            this.loadingGroups = true
            httpClient
            .post(`${process.env.VUE_APP_API_URL}groups/condition?page=${0}&size=${10}`, {
              conditions: [
                {
                  field: 'name',
                  conditionalOperator: 'LIKE_START',
                  value: searchText
                },
              ]
            })
            .then(({ data }) => {
              this.loadingGroups = false;
              this.groups = data.data.content;
              this.groups.unshift({id: null, name: 'Todos'});
            })
            .catch((error) => {
              this.loadingGroups = false
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }, 500);
        }
      },

      async searchSubGroup (searchText) {
        if (!this.loadingSubGroups && (searchText && searchText.length)) {
          if (this.subGroupSearchTimeout) {
            clearTimeout(this.subGroupSearchTimeout);
          }

          this.subGroupSearchTimeout = setTimeout(() => {
            this.loadingSubGroups = true
            httpClient
            .post(`${process.env.VUE_APP_API_URL}sub-groups/condition?page=${0}&size=${10}`, {
              conditions: [
                {
                  field: 'name',
                  conditionalOperator: 'LIKE_START',
                  value: searchText
                },
              ]
            })
            .then(({ data }) => {
              this.loadingSubGroups = false;
              this.subGroups = data.data.content;
              this.subGroups.unshift({id: null, name: 'Todos'});
            })
            .catch((error) => {
              this.loadingSubGroups = false
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }, 500);
        }
      },

      async searchBrand (searchText) {
        if (!this.loadingBrands && (searchText && searchText.length)) {
          if (this.brandSearchTimeout) {
            clearTimeout(this.brandSearchTimeout);
          }

          this.brandSearchTimeout = setTimeout(() => {
            this.loadingBrands = true
            httpClient
            .post(`${process.env.VUE_APP_API_URL}brands/condition?page=${0}&size=${10}`, {
              conditions: [
                {
                  field: 'name',
                  conditionalOperator: 'LIKE_START',
                  value: searchText
                },
              ]
            })
            .then(({ data }) => {
              this.loadingBrands = false;
              this.brands = data.data.content;
              this.brands.unshift({id: null, name: 'Todas'});
            })
            .catch((error) => {
              this.loadingBrands = false
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }, 500);
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      saveItem(item, event) {
        if (typeof event == "boolean") {
          item.automaticItemLot = event;
        }

        item = this.prepareItem(item);

        this.saving = true;
        httpClient.patch(`${process.env.VUE_APP_API_URL}items/${item.id}`, {
          automaticItemLot: item.automaticItemLot,
          defaultLocationId: item.defaultLocationId
        }).then(() => {
          this.saving = false;
          this.$notify.success('Item salvo com sucesso');
        }).catch(error => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar o item')
          }
        });
      },

      prepareItem(item) {
        if (item.defaultLocation) {
          if (item.defaultLocation.id) {
            item.defaultLocationId = item.defaultLocation.id;
          } else {
            if (typeof item.defaultLocation == 'string') {
              item.defaultLocationId = item.defaultLocation;
            } else {
              item.defaultLocationId = null;
            }
          }
        }

        return item;
      },

      async searchLocation(index, searchText) {
        if (!this.searchLocationInProcess && !this.saving && searchText && searchText.length) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          let conditions = [
            {
              field: "name",
              conditionalOperator: "LIKE_START",
              value: searchText,
            }
          ];

          if (index > -1) {
            conditions.push({
              logicalOperator: "OR",
              conditions: [
                {
                  field: "item.id",
                  value: this.items[index].id,
                  joinType: "LEFT",
                },
                {
                  field: "item.id",
                  conditionalOperator: "IS_NULL",
                  joinType: "LEFT",
                },
              ],
            });
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.searchLocationInProcess = true;

            httpClient
              .post(
                `${
                  process.env.VUE_APP_API_URL
                }locations/condition?page=${0}&size=${10}`,
                {
                  conditions: conditions,
                }
              )
              .then(({ data }) => {
                this.searchLocationInProcess = false;

                data.data.content.slice().reverse().forEach(location => {
                  let index = this.locations.findIndex(l => l.id === location.id);

                  if (index > -1) {
                    this.locations.splice(index, 1);
                  }

                  this.locations.unshift(location);
                });

                if (!this.locations.find(l => !l.id)) {
                  this.locations.push({ id: null, name: "Nenhum" });
                }
              })
              .catch((error) => {
                this.searchLocationInProcess = false;
                if (error.message) {
                  this.$notify.error(error);
                }
              });
          }, 500);
        }
      },

      openLocationBarcodeInput(index) {
        this.selectedItemIndex = index;
        this.$bvModal.show("location-barcode-input-modal");
      },

      readLocationBarcode(barcode) {
        if (!this.loadingLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingLocationBarcode = true;
            httpClient
              .get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
              .then((data) => {
                this.loadingLocationBarcode = false;

                if (data.data.data) {
                  if (
                    data.data.data.item &&
                    data.data.data.item.id !== this.items[this.selectedItemIndex].id
                  ) {
                    this.$notify.textError(
                      "Endereço não é compatível com este item"
                    );
                  } else {
                    this.locations.unshift(data.data.data);
                    this.items[this.selectedItemIndex].defaultLocation = data.data.data;
                    this.$bvModal.hide("location-barcode-input-modal");
                    this.$notify.success("Endereço encontrado");
                    this.saveItem(this.items[this.selectedItemIndex]);
                  }
                } else {
                  this.$notify.textError("Endereço não encontrado");
                }

                this.locationBarcodeAttempts++;
              })
              .catch((error) => {
                this.loadingLocationBarcode = false;
                if (error.message) {
                  this.$notify.error(error);
                } else {
                  this.$notify.textError(
                    "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                  );
                }

                this.locationBarcodeAttempts++;
              });
          } else {
            this.$notify.textError(
              "Código inválido. Por favor insira um código de barras válido"
            );
          }
        }

        this.locationBarcodeAttempts++;
      },

      openImagemModal(images) {
        if (images.length) {
          this.itemImages = images;
          this.$refs.imageModal.showModal();
        }
      },

      setIsUserManager() {
        this.authUser = shared.getLocalStorageObj('auth/user');

        if (this.authUser && this.authUser.profile && this.authUser.profile.modules) {
          let profileModule = this.authUser.profile.modules.find(pm => {
            return pm.name && pm.name.toString().toUpperCase() == "ITEMS"
          });

          this.isUserManager = profileModule && profileModule.canExecute;
        }
      },

      listenToScrollBottom() {
        window.onscroll = () => {
          if (
            !this.loadingRows
            && !this.saving
            && this.hasNextRow
            && this.$route.fullPath === this.ownRoute
            && auth.isScrollOnBottom()
          ) {
            this.pageChanged(this.currentPage+1);
          }
        }
      },

      async showItemDetails (item) {
        this.$router.push('/warehouse/items/details/' + item.id)
      },

      beforeRouteLeave() {
        shared.saveScrollPosition('item/scrollPosition');
      },
    },

    watch: {
      'selectedAutomaticLotOption': function() {
        shared.setLocalStorageObj('item/selectedAutomaticLotOption', this.selectedAutomaticLotOption);
        this.onFiltering();
      },
      'selectedFamily': function(newVal, oldVal) {
        shared.setLocalStorageObj('item/selectedFamily', this.selectedFamily);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedGroup': function(newVal, oldVal) {
        shared.setLocalStorageObj('item/selectedGroup', this.selectedGroup);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedSubGroup': function(newVal, oldVal) {
        shared.setLocalStorageObj('item/selectedSubGroup', this.selectedSubGroup);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedBrand': function(newVal, oldVal) {
        shared.setLocalStorageObj('item/selectedBrand', this.selectedBrand);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      }
    }
  }
</script>

<style scoped>
  .items {
    cursor: pointer;
  }

  #loading-animation {
    position: fixed;
    top: 50%;
    left: 50%;
  }
</style>
