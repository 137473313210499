<template>
  <div class="animated fadeIn">
    <div>
      <items-table
        ref="itemsTable"
        id="list-item"
        resourceName="itemResourcesList"
        :url="endpoints.findAll"
        :fields="fields"
      >
      </items-table>
    </div>
  </div>
</template>

<script>
  import ItemsTable from '@/components/tables/items-table'

  export default {
    name: 'items',
    components: { ItemsTable },

    data () {
      return {
        fields: [
          { key: 'description', label: 'Descrição', sortable: true },
          { key: 'totalCapacity', label: 'Capacidade de armazenamento' },
          { key: 'totalAllocated', label: 'Alocados' },
          { key: 'totalAllocations', label: 'Número de Endereços' },
          { key: 'actions', label: '' },
        ],
        endpoints: {
          findAll: process.env.VUE_APP_API_URL + 'items'
        }
      }
    },

    mounted: async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: 'Lista de Itens', size: 4 })
    },

    beforeRouteLeave (to, from, next) {
      this.$refs.itemsTable.beforeRouteLeave();
      next(true);
    },
  }
</script>

<style scoped>

</style>
